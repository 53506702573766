
body {
  background-color: white;
}


* {
    --amplify-colors-primary-10: var(--amplify-colors-blue-10);
    --amplify-colors-primary-20: var(--amplify-colors-blue-20);
    --amplify-colors-primary-40: var(--amplify-colors-blue-40);
    --amplify-colors-primary-60: var(--amplify-colors-blue-60);
    --amplify-colors-primary-80: var(--amplify-colors-blue-80);
    --amplify-colors-primary-90: var(--amplify-colors-blue-90);
    --amplify-colors-primary-100: var(--amplify-colors-blue-100);
    --amplify-components-tabs-item-active-color:var(--amplify-colors-blue-80);;
    --amplify-components-tabs-item-active-border-color:var(--amplify-colors-blue-80);;
    --amplify-components-tabs-item-hover-color: var(--amplify-colors-blue-80);
    --amplify-components-tabs-item-color: var(--amplify-colors-neutral-60);
    --amplify-components-button-hover-background-color : var(--amplify-colors-neutral-40);
    --amplify-components-button-hover-border-color : var(--amplify-colors-neutral-40);
    --amplify-components-button-active-background-color : var(--amplify-colors-neutral-40);
    --amplify-components-button-active-border-color : var(--amplify-colors-neutral-40);
} 
  
  *{
    .btn-primary {
      background-color: var(--amplify-colors-primary-80);
    }
    .bg-primary {
      background-color: var(--amplify-colors-primary-80) !important; 
    }
  }

/* Bootstrap Overrides */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler {
  font-size: 1rem;
  border-color: rgb(255, 255, 255) !important;
}
