.themeContainer {
  overflow-y: hidden;
  overflow-y: auto;
}

.scrollableContent::-webkit-scrollbar {
    display: none;
  }

.designerBackground {
  background-color: gray;
  width: 100%;
  height: 100%;
}
.designerBackgroundImageLoader {
  width: 100%;
}
.themeContainer {
  background-color: transparent;
  /* margin: 40px; */
  margin: 40px;
  /* height: 100vh; */
  top: 40px;
  position: sticky;
  object-fit: contain; /* This will ensure the content scales to fit the container while maintaining its aspect ratio */
  user-select: none;
}

.themeContainer,
.loading {
  height: 100vh;
  padding: 0px;
  margin: 0px;
}


.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    min-width: 100px;
    min-height: 100px;
}

.handle {
    float: left;
    width: 5px;
    cursor: ew-resize;
    background: #ddd;
    background: var(--amplify-colors-primary-100);
  }

