/* Medums screens only */
@media (min-width: 768px) {
  .navLinkActive {
    /* color: #0a58ca !important; */
    /* background-color: rgb(226, 232, 240); */
    /* Add underline */
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    text-decoration: none !important;

  }
}
@media (max-width: 767px) {
  .navLinkActive {
    /* background-color: blue; */
    text-decoration: underline !important;
    text-underline-offset: 5px;
    /* Correcting the typo and applying padding for small screens */
    padding-bottom: 5px; /* Adjust as needed */
  }
}


.navLinkActive:hover {
  /* background-color: rgb(226, 232, 240); */
}
