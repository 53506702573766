.footerCustom { /* Changed class name to footerCustom */
  background-color: var(--amplify-colors-primary-100);
  border-top: 1px solid var(--amplify-colors-neutral-60); /* Changed border-top */
  /* box-shadow: 0px -10px 20px 2px hsla(210, 50%, 10%, 0.25); Changed box-shadow */
  margin: 0px;
  padding: 0px;
}

.footerButtonsContainer { /* Changed class name to footerButtonsContainer */
  max-width: 1440px;
  margin: 0px auto;
}

.footerHeader { /* Changed class name to footerHeader */
  padding-left: 20px;
  /* font-weight: var(--amplify-font-weights-medium); */
  color: var(--amplify-colors-white) !important;
  font-size: var(--amplify-font-sizes-small);
}

.footerLink { /* Changed class name to footerLink */
  color: var(--amplify-colors-white);
  font-weight: var(--amplify-font-weights-medium);
  font-size: var(--amplify-font-sizes-small);
}
