.banner {
  /* height: 50px; */
  background-color: var(
    --amplify-components-alert-warning-background-color
  ); /* Change this to the color you want */
  color: var(
    --amplify-components-alert-warning-color
  ); /* Change this to the color you want */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
