
.dashboard-container {
}

.layout {
  min-width: 210px;
  
    z-index: 1;
    .header {
      display: flex;
      align-items: center;
      padding: 20px;
    }

    .content {
      padding: 12px 50px;
      display: flex;
      flex-direction: column;
    }
  
    .footer {
      text-align: center;
      margin-top: auto;
      margin-bottom: 20px;
      padding: 20px;
    }
  }
  
  
  .sidebar {
    color: #7d84ab;
    overflow-x: hidden !important;
    position: relative;
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: lighten(#0c1e35, 15);
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #0c1e35;
    }
  
    .image-wrapper {
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: none;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    &.has-bg-image .image-wrapper {
      display: block;
    }
  
    .gallery-sidebar-layout {
      height: auto;
      min-height: 100vh;
      min-width: 250px;
      min-width: 15vw;
      display: flex;
      flex-direction: column;
      position: relative;
      background-color: #0c1e35;
      background-color: var(--amplify-colors-primary-100);
      z-index: 2;
  
      .sidebar-header {
        padding: 20px;
        /* height: 100px; */
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-items: center;
        align-items: center;
        /* padding: 0 20px; */
        > span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .sidebar-content {
        flex-grow: 1;
        /* padding: 10px 0; */
      }
      .sidebar-footer {
        height: 230px;
        min-height: 280px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        > span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  
  @keyframes swing {
    0%,
    30%,
    50%,
    70%,
    100% {
      transform: rotate(0deg);
    }
  
    10% {
      transform: rotate(10deg);
    }
  
    40% {
      transform: rotate(-10deg);
    }
  
    60% {
      transform: rotate(5deg);
    }
  
    80% {
      transform: rotate(-5deg);
    }
  }
  
  .layout {
    .sidebar {
      .menu {
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
        }
        .menu-header {
          font-weight: 600;
          padding: 10px 25px;
          font-size: 0.8em;
          letter-spacing: 2px;
          transition: opacity 0.3s;
          opacity: 0.5;
          text-align: left;
        }
        .menu-title {
          font-size: 0.9em;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex-grow: 1;
          transition: color 0.3s;
          text-align: left;
        }
        .menu-item {
          a {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 20px;
            color: #7d84ab;
  
            .menu-icon {
              font-size: 1.2rem;
              width: 20px;
              min-width: 20px;
              height: 35px;
              line-height: 35px;
              text-align: center;
              display: inline-block;
              margin-right: 10px;
              border-radius: 2px;
              transition: color 0.3s;
              i {
                display: inline-block;
              }
            }
  
            .menu-title {
              font-size: 0.9em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex-grow: 1;
              transition: color 0.3s;
              text-align: left;
            }

            .menu-title-nav {
              font-size: 0.9em;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex-grow: 1;
              transition: color 0.3s;
              text-align: left;
            }
            .menu-prefix,
            .menu-suffix {
              display: inline-block;
              padding: 5px;
              opacity: 1;
              transition: opacity 0.3s;
            }
            &:hover {
              .menu-title {
                color: #dee2ec;
              }
              .menu-icon {
                color: #dee2ec;
                i {
                  animation: swing ease-in-out 0.5s 1 alternate;
                }
              }
              &::after {
                border-color: #dee2ec !important;
              }
            }
          }
  
          &.sub-menu {
            position: relative;
            > a {
              &::after {
                content: '';
                transition: transform 0.3s;
                border-right: 2px solid currentcolor;
                border-bottom: 2px solid currentcolor;
                width: 5px;
                height: 5px;
                transform: rotate(-45deg);
              }
            }
  
            > .sub-menu-list {
              padding-left: 20px;
              display: none;
              overflow: hidden;
              z-index: 999;
            }
            &.open {
              > a {
                color: #dee2ec;
                &::after {
                  transform: rotate(45deg);
                }
              }
            }
          }
  
          &.active {
            > a {
              .menu-title {
                color: #dee2ec;
              }
              &::after {
                border-color: #dee2ec;
              }
              .menu-icon {
                color: #dee2ec;
              }
            }
          }
        }
        > ul > .sub-menu > .sub-menu-list {
          background-color: #0b1a2c;
        }
  
        &.icon-shape-circle,
        &.icon-shape-rounded,
        &.icon-shape-square {
          .menu-item a .menu-icon {
            background-color: #0b1a2c;
          }
        }
  
        &.icon-shape-circle .menu-item a .menu-icon {
          border-radius: 50%;
        }
        &.icon-shape-rounded .menu-item a .menu-icon {
          border-radius: 4px;
        }
        &.icon-shape-square .menu-item a .menu-icon {
          border-radius: 0;
        }
      }
  
      &:not(.collapsed) {
        .menu > ul {
          > .menu-item {
            &.sub-menu {
              > .sub-menu-list {
                visibility: visible !important;
                position: static !important;
                transform: translate(0, 0) !important;
              }
            }
          }
        }
      }
  
      &.collapsed {
        .menu > ul {
          > .menu-header {
            opacity: 0;
          }
          > .menu-item {
            > a {
              .menu-prefix,
              .menu-suffix {
                opacity: 0;
              }
            }
            &.sub-menu {
              > a {
                &::after {
                  content: '';
                  width: 5px;
                  height: 5px;
                  background-color: currentcolor;
                  border-radius: 50%;
                  display: inline-block;
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  border: none;
                  transform: translateY(-50%);
                }
                &:hover {
                  &::after {
                    background-color: #dee2ec;
                  }
                }
              }
              > .sub-menu-list {
                transition: none !important;
                width: 200px;
                margin-left: 3px !important;
                border-radius: 4px;
                display: block !important;
              }
            }
            &.active {
              > a {
                &::after {
                  background-color: #dee2ec;
                }
              }
            }
          }
        }
      }
      &.has-bg-image {
        .menu {
          &.icon-shape-circle,
          &.icon-shape-rounded,
          &.icon-shape-square {
            .menu-item a .menu-icon {
              background-color: rgba(#0b1a2c, 0.6);
            }
          }
        }
        &:not(.collapsed) {
          .menu {
            > ul > .sub-menu > .sub-menu-list {
              background-color: rgba(#0b1a2c, 0.6);
            }
          }
        }
      }
    }
  
    &.rtl {
      .sidebar {
        .menu {
          .menu-item {
            a {
              .menu-icon {
                margin-left: 10px;
                margin-right: 0;
              }
            }
  
            &.sub-menu {
              > a {
                &::after {
                  transform: rotate(135deg);
                }
              }
  
              > .sub-menu-list {
                padding-left: 0;
                padding-right: 20px;
              }
              &.open {
                > a {
                  &::after {
                    transform: rotate(45deg);
                  }
                }
              }
            }
          }
        }
  
        &.collapsed {
          .menu > ul {
            > .menu-item {
              &.sub-menu {
                a::after {
                  right: auto;
                  left: 10px;
                }
  
                > .sub-menu-list {
                  margin-left: -3px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  
  * {
    box-sizing: border-box;
  }
  
  /* body {
    margin: 0;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    color: #3f4750;
    font-size: 1rem;
  } */
  
  a {
    text-decoration: none;
  }
  
  @media (max-width: 576px) {
    #btn-collapse {
      display: none;
    }
  }
  
  
 
  .layout {
    .sidebar {
      .pro-sidebar-logo {
        display: flex;
        align-items: center;
  
        > div {
          width: 35px;
          min-width: 35px;
          height: 35px;
          min-height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          color: white;
          font-size: 8px;
          font-weight: 700;
          background-color: #ff8100;
          margin-right: 10px;
        }
  
        > h5 {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 20px;
          line-height: 30px;
          transition: opacity 0.3s;
          opacity: 1;
          color: white;
          color: #7d84ab;
        }
      }
  
      .footer-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 0.8em;
        padding: 20px 0;
        border-radius: 8px;
        width: 180px;
        /* min-width: 190px; */
        margin: 0 auto;
        background-color: #162d4a;
        img.react-logo {
          width: 40px;
          height: 40px;
          margin-bottom: 10px;
        }
        a {
          color: #fff;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
  
      .sidebar-collapser {
        transition: left, right, 0.3s;
        position: fixed;
        left: 208px;
        top: 40px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #00829f;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
        transform: translateX(50%);
        z-index: 111;
        cursor: pointer;
        color: white;
        box-shadow: 1px 1px 4px #0c1e35;
      }
  
      &.collapsed {
        .pro-sidebar-logo {
          > h5 {
            opacity: 0;
          }
        }
        .footer-box {
          display: none;
        }
        .sidebar-collapser {
          left: 60px;
          i,svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #6c757d;
  
    &.primary {
      background-color: #ab2dff;
    }
  
    &.secondary {
      background-color: #079b0b;
    }
  }
  
  .sidebar-toggler {
    position: fixed;
    right: 20px;
    top: 20px;
  }
  
  .social-links {
    a {
      margin: 0 10px;
      color: #3f4750;
    }
  }



