/* .tableActionIcons  {

    > svg {
        max-width: 25px;
        min-width: 15px;
    }
} */



.table {
    /* border-radius: 10px; */
    overflow: hidden;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .1); /* Reduced box shadow */
}
.tableHeader {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
    /* margin-bottom: 20px; */
    text-align: left;
    /* font-family: 'Lato', sans-serif; */
    letter-spacing: .5px;
}
.table thead {
    background-color: #f8f9fa;
}
.tableHeaderContainer {
    margin-bottom: 20px;
    min-height: 20px;
}

.table thead th {
    border-bottom: none;
}

.table tbody tr:last-child td {
    border-bottom: none;
}

.table td, .table th {
    text-align: center;
    vertical-align: middle;
  }
  
  .tableActionIcons {
    justify-content: center;
    align-items: center;
  }



  