/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

/* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}

/* MARKETING CONTENT
  -------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
/* rtl:end:ignore */

/* Featurettes
  ------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featuretteHeading {
  letter-spacing: -0.05rem;
}
.featuretteHeading {
  color: var(--amplify-colors-primary-80);
  letter-spacing: -0.05rem;
  padding-bottom: 20px;
}

/* rtl:end:remove */

/* RESPONSIVE CSS
  -------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featuretteHeading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featuretteHeading {
    /* margin-top: 7rem; */
  }
}

.videoContainer {
  /* position: relative;  */
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.aiVideoBox {
  width: 351px;
  height: 565px;
  justify-content: center;
  align-items: center;
}

.aiFrameContainer {
  position: relative;
}

.phoneFrame {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: scale(1.07);
  /* z-index: 0; */
}

.aiVideoBox {
  border-radius: 47px;
  /* z-index: 1; */
}

@media screen and (max-width: 992px) {
  .aiVideoBox {
    width: calc(350px - 100px);
    height: calc(565px - 158px);
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .phoneFrame {
    margin-top: 40px;
    top: 40px;
    height: 85%;

  }

  .aiVideoBox {
    margin-top: 80px;
    width: 270px;
    height: 450px;
    justify-content: center;
    align-items: center;
    border-radius: 38px;
  }
}
