@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;700&display=swap");

.opp {
    background-color: white;

}
.landingPage2-herosection {
    background-image: url("./bgImage.png");
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the image */
    height: 100vh;;
}

/* 

.landingPage2-herosection2 {
    background:linear-gradient(to left, #c7c7c736, #ffffff);
    background:linear-gradient(to bottom, #9099f22e, #ffffff);
}


.opp {
    background:linear-gradient(to left, #c7c7c736, #ffffff);
    background:linear-gradient(to bottom, #ffffff, #9099f22e);
}

.linGradieant { 
    background:linear-gradient(to bottom, #9099f22e, #d4d8ff);
} */

