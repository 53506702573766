@media (min-width: 992px) {
    .dynamicHeight {
      height: 80vh;
    }
    
    .padding40 {
        animation: slideIn 1s ease-out forwards;
        }
        
  }


/* Define keyframes for the slide-in effect */
@keyframes slideIn {
    from {
    }
    to {
        margin-top: 50px;
    }
  }
  
  