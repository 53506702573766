@media (min-width: 992px) {
  .dynamicHeight {
    height: 80vh;
  }

  .featureCardPadding {
    /* padding-top: 50px; */
  }

  .heroContainer {
    height: 63vh;
  }

  .Carousel_1 {
    position: absolute;
    top: 100px;
    left: 100px;
    z-index: 3;
    border: black .1px solid;
  }
  .Carousel_2 {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 2;
    /* border: red 1px solid; */
  }
  .Carousel_3 {
    top: 0;
    left: 0;
    z-index: 1;
    /* border: green 1px solid; */
  }
}

.gradient {
  /* Triangle gradiant */
  width: 100vw;
  height: 500px;
  background-color: #edebf3;
  /* clip-path: polygon(0 0, 100% 0, 0 100%); */
  background: linear-gradient(to bottom right, #edebf3 50%, transparent 50%);
  background: linear-gradient(to bottom right, #fb9239ab 50%, transparent 50%);
  position: absolute;

  /* margin-top: -40px; */
}

.gradient2 {
  /* Triangle gradiant */
  width: 100vw;
  height: 100px;
  top: 1px;
  left:1px;
  background-color: #edebf3;
  /* clip-path: polygon(0 0, 100% 0, 0 100%); */
  background: linear-gradient(#fb9239ab 50%, transparent 50%);
  position: absolute;
  transform: rotate(54deg);
  z-index: 1;

  clip-rule: polygon(0 0, 100% 0, 0 100%);
  margin-top: -50px;
  /* margin-top: -40px; */
}

.gradient1 {
  background: linear-gradient(to top left, #fb9239ab 50%, transparent 50%);
  border-top: #fb9239ab;
}

.mainContainer {
  position: relative;
}

.heroContainer {
  position: relative;
  z-index: 1;
  /* padding-top: 75px; */
}
.heroImageContainer {
  /* border: 2px red solid; */
  position: relative;
}
.DesktopImage {
  top: 100px;
  left: 100px;
  position: absolute;
}

.PhoneImage {
  top: 100px;
  left: 100px;
  position: absolute;
  z-index: 3;
}

.heroHeader {
  /* font-size: 60px; */
  font-weight: 700;
  color: #00057b;
  margin: 0px;
}
.heroHeaderSub {
  /* font-size: 52px; */
  font-weight: 700;
  color: black;
}

.heroButton {
  background-color: #00057b;
  color: white;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.heroPricingButton {
  font-weight: 700;
}

.flexAlign {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: center; /* Centers items horizontally, adjust as needed */
}

.designButton {
  align-items: flex-start;
  appearance: button;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: #00057b;
  background-image: none;
  background-origin: padding-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  border: 1px solid #00057b;
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: block;
  float: left;
  font-size: 14px;
  font-weight: 400;
  height: 35.1406px;
  letter-spacing: 2px;
  line-height: 17.1429px;
  margin: 0px;
  outline: none;
  overflow: visible;
  padding: 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  transition: color 0.2s ease-out;
  user-select: none;
  vertical-align: middle;
  /* max-width: 280.938px; */
  z-index: 2;
}

.designButton_active {
  align-items: flex-start;
  appearance: button;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: white;
  background-image: none;
  background-origin: padding-box;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  border: 1px solid #00057b;
  border-radius: 0px;
  box-shadow: none;
  box-sizing: border-box;
  color: #00057b;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 14px;
  font-weight: 400;
  height: 35.1406px;
  letter-spacing: 2px;
  line-height: 17.1429px;
  margin: 0px;
  outline: none;
  overflow: visible;
  padding: 8px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-indent: 0px;
  text-rendering: auto;
  text-shadow: none;
  text-size-adjust: 100%;
  text-transform: none;
  transition: color 0.2s ease-out;
  user-select: none;
  vertical-align: middle;
  /* width: 280.938px; */
  z-index: 2;
}

.Carousel {
  position: relative;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
}

.dot {
  height: 8px;
  width: 8px;
  margin: 0 5px;
  background-color: #717171;
  border-radius: 50%;
  display: inline-block;
}
/* Active dot */
.dotActive {
  background-color: black;
}

.arrowContainer {
  /* position: absolute; */
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  cursor: none;
  /* margin-top:-10px; */
}

.arrow {
  width: 15px;
  height: 15px;
  border-left: 5px solid gray;
  border-bottom: 5px solid gray;
  transform: rotate(-45deg);
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) rotate(-45deg);
  }
  40% {
    transform: translateY(10px) rotate(-45deg);
  }
  60% {
    transform: translateY(5px) rotate(-45deg);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1s;
  visibility: visible !important;

  animation-fill-mode: both;
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(20px);
  }
  to {
    opacity: 0;
    transform: translateY(0);
  }
}

.disappear {
  visibility: hidden;
}


@keyframes slightlyGettingBigger {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.slightlyGettingBigger {
  animation: slightlyGettingBigger 2s infinite;
}