.tableActionIcons {
  > svg {
    max-width: 25px;
    min-width: 15px;
  }
}

.projectTableMainContainer {
  min-width: 100%;
  padding-bottom: 100px;
}

.svgActionIcons {
  min-width: 25px;
  color: black;
}
.svgActionIcons:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
}

.table {
  /* border-radius: 10px; */
  overflow: hidden;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1); /* Reduced box shadow */
}
.tableHeader {
  font-size: 40px;
  font-weight: 600;
  color: #000000;
  color: var(--amplify-colors-primary-80);
  /* margin-bottom: 20px; */
  text-align: left;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.5px;
}
.table thead {
  background-color: var(--amplify-colors-primary-80);
  color: white;
}
.tableHeaderContainer {
  margin-bottom: 20px;
  min-height: 20px;
}

.table thead th {
  border-bottom: none;
}

.table tbody tr:last-child td {
  border-bottom: none;
}
/* 
.table td, .table th {
    text-align: center;
    vertical-align: middle;
  }
  
  .tableActionIcons {
    justify-content: center;
    align-items: center;
  }
   */
.createGalleryButton {
  font-size: 14px;
  max-width: 150px;
  max-height: 40px;
  background-color: var(--amplify-colors-primary-80);
}

.createGalleryButton:hover {
  background-color: var(--amplify-colors-primary-100);
  cursor: pointer;
}

.createGalleryButton:active {
  background-color: var(--amplify-colors-primary-100);
  cursor: pointer;
}
.createGalleryButton:focus {
  background-color: var(--amplify-colors-primary-100);
  cursor: pointer;
}

.IconText {
  font-size: 14px;
  color: black;
  margin-left: 5px;
  margin-right: 5px;
  /* Non Clickable */
  pointer-events: none;
}

/* On small screens change font size to 12px */
@media only screen and (max-width: 600px) {
  .createGalleryButton {
    font-size: 12px;
  }
}

/* New project table */

.projectCard {
  border: 1px solid #cac4d0;
  border-radius: 12px;
  background-color: #f9f9f9;
  /* max-width: 700px; */
}

.pagination {
}

.pageLink {
  max-width: 32px;
  max-height: 32px;
  border: none;
  color: black;
  margin: 10px;
  font-weight: 600;
  border-radius: 8px;
}

.pageLinkActive {
  border: none;
  /* padding: 0px 10px; */
  color: white;
  font-weight: 600;
  border-radius: 8px;
  background-color: black;
}

.heroButton {
  background-color: #00057b;
  color: white;
  padding: 10px 20px;
  border-radius: 100px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.flexAlign {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: center; /* Centers items horizontally, adjust as needed */
}

.customModal {
  max-width: 1200px ;
}

.subText {
  font-size: 12px;
}