.heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.content {
  max-width: 800px;
  text-align: left;
  line-height: 1.8;
  margin-bottom: 40px;
}

h2 {
  font-size: 1.8rem;
  margin-top: 20px;
  color: #333;
}

p {
  font-size: 1rem;
  color: #555;
}

.button {
  margin-top: 20px;
  font-size: 1rem;
  padding: 10px 20px;
}
