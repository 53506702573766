.lolback {
    background: linear-gradient(
      to bottom,
      #4099f42e 0%,
      #d4d8ff 25%,
      #9099f22e 50%,
      #ffffff 75%,
      #4099f42e 100%
    );
  
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #4099f42e 25%,
      #e9ecef 50%,
      #ffffff 75%,
      #4099f42e 100%
    );
  
  }
  