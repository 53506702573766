@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.scrollUpButton {
  position: absolute;
  color: black;
  top: 45px;
  /* left: 0px; */
  z-index: 2;
  animation: pulse 2s infinite;
}
