@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap");

.mainImageContainer {
  position: relative;
  width: 100vw;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.ParallelThemeMainHeader {
  font-size: 3.25rem;
}

.ParallelThemeMainHeader,
.ParallelThemeMainSubtext,
.ParallelThemeButton,
.navbar {
  color: white;
  font-family: "Syne", sans-serif;
  z-index: 3;
}

.ParallelThemeMainSubtext {
  font-size: 14px;
  letter-spacing: 1.12px;
}

.ParallelThemeButton {
  border: 1px solid white;
  font-size: 11px;
  font-family: lato;
  letter-spacing: 1.6px;
  align-items: center;
  background-color: transparent; /* 'rgba(0, 0, 0, 0)' can be simplified to 'transparent' */
  border: 1px solid #ffffff; /* Simplified RGB to hex */
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Lato", sans-serif;
  font-size: 11px;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  letter-spacing: 1.65px;
  line-height: 16px;
  margin: 0;
  padding: 0 24px;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  -webkit-font-smoothing: antialiased;
}

.ParallelThemeButton:focus {
  outline: none;
  border-color: #ffffff; /* Keep the border color consistent with the rest of the button */
}

.ParallelThemeButton:hover {
  /* Adjust the hover styles as needed, for example: */
  background-color: #f2f2f2; /* Light grey background on hover */
  color: #000000; /* Black text on hover */
  border-color: #f2f2f2; /* Light grey border on hover */
}

.galleryContainerMain {
  /* height: 90vh; */
  /* min-height: 50vh; */
  /* margin-top: -2rem; */
  min-height: 100vh;
  padding-bottom: 20%;
}

.infinitescrollcomponent {
  margin-top: -2rem;
}

.uploadphototabheader {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: "Lato", sans-serif;
  }
}

.backgroundImage {
  /* position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1; */

  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  object-fit: cover;

  z-index: 1;
  object-position: center center;
  /* border: 2px solid white; */
}


.hr {
  /* position: absolute; */
  top: 20px;
  bottom: 20px;
  right: 20px;
  left: 20px;
  width: 90%;
  z-index: 3;
}

.mainContent {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  height: 100%;
  /* place-content: end; */
}

.bgwhite {
  background-color: white;
  z-index: 0;
}

.gallery-constainer {
  width: 95vw;
  margin: 0 auto;
}

.download-section-gallery {
  visibility: hidden;
}

.upload-photo-stab {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.lastShareIcon {
  margin-right: 10px;
}

/* Chrome, Safari, Edge */
body::-webkit-scrollbar {
  display: none;
}

/* Firefox */
body {
  scrollbar-width: none;
}


