@font-face {
  font-family: "RoxboroughCF";
  src: url("aab179568a558b68c94482f2e789a1f2.eot");
  src: url("aab179568a558b68c94482f2e789a1f2.eot?#iefix")
      format("embedded-opentype"),
    /* url('aab179568a558b68c94482f2e789a1f2.woff2') format('woff2'), */
      url("aab179568a558b68c94482f2e789a1f2.woff") format("woff"),
    url("aab179568a558b68c94482f2e789a1f2.ttf") format("truetype"),
    url("aab179568a558b68c94482f2e789a1f2.svg#") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ManisonExtraBold";
  src: url("ManisonDEMO-800.eot");
  src: url("ManisonDEMO-800.eot?#iefix") format("embedded-opentype"),
    url("ManisonDEMO-800.woff2") format("woff2"),
    url("ManisonDEMO-800.woff") format("woff"),
    url("ManisonDEMO-800.ttf") format("truetype"),
    url("ManisonDEMO-800.svg#ManisonDEMO-800") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ManisonMedium";
  src: url("ManisonDEMO-500.eot");
  src: url("ManisonDEMO-500.eot?#iefix") format("embedded-opentype"),
    url("ManisonDEMO-500.woff2") format("woff2"),
    url("ManisonDEMO-500.woff") format("woff"),
    url("ManisonDEMO-500.ttf") format("truetype"),
    url("ManisonDEMO-500.svg#ManisonDEMO-500") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ManisonExtraLight";
  src: url("ManisonDEMO-200.eot");
  src: url("ManisonDEMO-200.eot?#iefix") format("embedded-opentype"),
    url("ManisonDEMO-200.woff2") format("woff2"),
    url("ManisonDEMO-200.woff") format("woff"),
    url("ManisonDEMO-200.ttf") format("truetype"),
    url("ManisonDEMO-200.svg#ManisonDEMO-200") format("svg");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ManisonSemibold";
  src: url("ManisonDEMO-600.eot");
  src: url("ManisonDEMO-600.eot?#iefix") format("embedded-opentype"),
    url("ManisonDEMO-600.woff2") format("woff2"),
    url("ManisonDEMO-600.woff") format("woff"),
    url("ManisonDEMO-600.ttf") format("truetype"),
    url("ManisonDEMO-600.svg#ManisonDEMO-600") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ManisonBlack";
  src: url("ManisonDEMO-900.eot");
  src: url("ManisonDEMO-900.eot?#iefix") format("embedded-opentype"),
    url("ManisonDEMO-900.woff2") format("woff2"),
    url("ManisonDEMO-900.woff") format("woff"),
    url("ManisonDEMO-900.ttf") format("truetype"),
    url("ManisonDEMO-900.svg#ManisonDEMO-900") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ManisonLight";
  src: url("ManisonDEMO-300.eot");
  src: url("ManisonDEMO-300.eot?#iefix") format("embedded-opentype"),
    url("ManisonDEMO-300.woff2") format("woff2"),
    url("ManisonDEMO-300.woff") format("woff"),
    url("ManisonDEMO-300.ttf") format("truetype"),
    url("ManisonDEMO-300.svg#ManisonDEMO-300") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ManisonBold";
  src: url("ManisonDEMO-700.eot");
  src: url("ManisonDEMO-700.eot?#iefix") format("embedded-opentype"),
    url("ManisonDEMO-700.woff2") format("woff2"),
    url("ManisonDEMO-700.woff") format("woff"),
    url("ManisonDEMO-700.ttf") format("truetype"),
    url("ManisonDEMO-700.svg#ManisonDEMO-700") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ManisonRegular";
  src: url("ManisonDEMO-400.eot");
  src: url("ManisonDEMO-400.eot?#iefix") format("embedded-opentype"),
    url("ManisonDEMO-400.woff2") format("woff2"),
    url("ManisonDEMO-400.woff") format("woff"),
    url("ManisonDEMO-400.ttf") format("truetype"),
    url("ManisonDEMO-400.svg#ManisonDEMO-400") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JerinBlack";
  src: url("Jerin-Black.eot");
  src: url("Jerin-Black.eot?#iefix") format("embedded-opentype"),
    /* url('Jerin-Black.woff2') format('woff2'), */ url("Jerin-Black.woff")
      format("woff"),
    url("Jerin-Black.ttf") format("truetype"),
    url("Jerin-Black.svg#Jerin-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JerinBold";
  src: url("Jerin-Bold.eot");
  src: url("Jerin-Bold.eot?#iefix") format("embedded-opentype"),
    /* url('Jerin-Bold.woff2') format('woff2'), */ url("Jerin-Bold.woff")
      format("woff"),
    url("Jerin-Bold.ttf") format("truetype"),
    url("Jerin-Bold.svg#Jerin-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JerinRegular";
  src: url("Jerin-Regular.eot");
  src: url("Jerin-Regular.eot?#iefix") format("embedded-opentype"),
    /* url('Jerin-Regular.woff2') format('woff2'), */ url("Jerin-Regular.woff")
      format("woff"),
    url("Jerin-Regular.ttf") format("truetype"),
    url("Jerin-Regular.svg#Jerin-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JerinThin";
  src: url("Jerin-Thin.eot");
  src: url("Jerin-Thin.eot?#iefix") format("embedded-opentype"),
    /* url('Jerin-Thin.woff2') format('woff2'), */ url("Jerin-Thin.woff")
      format("woff"),
    url("Jerin-Thin.ttf") format("truetype"),
    url("Jerin-Thin.svg#Jerin-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JerinRound";
  src: url("Jerin-Round.eot");
  src: url("Jerin-Round.eot?#iefix") format("embedded-opentype"),
    /* url('Jerin-Round.woff2') format('woff2'), */ url("Jerin-Round.woff")
      format("woff"),
    url("Jerin-Round.ttf") format("truetype"),
    url("Jerin-Round.svg#Jerin-Round") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "JerinLight";
  src: url("Jerin-Light.eot");
  src: url("Jerin-Light.eot?#iefix") format("embedded-opentype"),
    /* url('Jerin-Light.woff2') format('woff2'), */ url("Jerin-Light.woff")
      format("woff"),
    url("Jerin-Light.ttf") format("truetype"),
    url("Jerin-Light.svg#Jerin-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Gilda+Display&display=swap");

.gilda-display-bold {
  font-family: "Gilda Display", serif;
  font-weight: 900;
  font-style: normal;
}

/* Side gallery designer */
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");
.alegreya-sans-sc-regular {
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.alegreya-sans-sc-bold {
  font-family: "Alegreya Sans SC", sans-serif;
  font-weight: 700;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC:ital,wght@0,400;0,500;0,700;0,800;0,900;1,400;1,500;1,700;1,800;1,900&display=swap');
.alegreya-sc-regular {
  font-family: "Alegreya SC", serif;
  font-weight: 400;
  font-style: normal;
}

.alegreya-sc-light-bold {
  font-family: "Alegreya SC", serif;
  font-weight: 600;
  font-style: normal;
}
.alegreya-sc-bold {
  font-family: "Alegreya SC", serif;
  font-weight: 700;
  font-style: normal;
}

/* Side gallery designer */
@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");
.alegreya-sans-regular {
  font-family: "Alegreya Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* Side gallery designer */
@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:ital,wght@0,400..700;1,400..700&display=swap");
.ibarra-real-nova-regular {
  font-family: "Ibarra Real Nova", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}


@import url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&display=swap');
.abhaya-libre-bold {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-style: normal;
}
.abhaya-libre-regular {
  font-family: "Abhaya Libre", serif;
  font-weight: 400;
  font-style: normal;
}




