
.btnPortfolio {
    background-color: transparent;
    border: 0;
    font-size: 20px;
    line-height: 1.5;
    text-transform: uppercase;
    font-family: "Libre Baskerville", serif;
    color: #999999;
    letter-spacing: 5px;
}
