.selfie-image {
  /* width: 100%; */
  max-height: 360px;
  object-fit: cover;
  z-index: 1;
  object-position: center center;
}

.upload-photo-tab-header {
  font-size: 30px;
}
.upload-photo-tab-subtext-note {
  padding: 20px;
  font-size: 12px;
  max-width: 400px;
  font-weight: 700;
}
.upload-photo-tab-subtext {
  margin: 0px;
  max-width: 400px;
}

.upload-photo-tab {
  max-width: none;
  /* min-height: 50vh; */
  margin: 0 auto 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}
#upload-photo-tab-subtext-header {
  font-weight: 700;
}

.upload-tab-button {
  border: 1px solid white;
  font-size: 11px;
  font-family: lato;
  letter-spacing: 1.6px;
  align-items: center;
  background-color: transparent; /* 'rgba(0, 0, 0, 0)' can be simplified to 'transparent' */
  border: 1px solid black; /* Simplified RGB to hex */
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  font-family: "Lato", sans-serif;
  font-size: 11px;
  font-weight: 500;
  height: 40px;
  justify-content: center;
  letter-spacing: 1.65px;
  line-height: 16px;
  margin: 0;
  padding: 0 24px;
  text-align: center;
  text-transform: uppercase;
  transition: color 0.25s ease-out, background-color 0.25s ease-out,
    border-color 0.25s ease-out;
  -webkit-font-smoothing: antialiased;
}
.upload-file-btn {
  text-align: center;
  margin-top: 30px;
}
.upload-file-btn input {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
}
.upload-file-btn label {
  max-width: none;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}
.upload-file-btn label img {
  width: 100%;
}

.upload-photo-tab-text-container {
}

.gallery-container-main {
  min-height: 50vh;
}

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
.caveat-500 {
  font-family: "Caveat", cursive !important;
  font-optical-sizing: auto;
  font-weight:500;
  font-style: normal;
}

