
.formControl, .customSelect {
  border: #D9D9D9 solid 1px;
  /* border-bottom: 1px solid #ccc; */
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 8px;
  background: none;
}
  .formControl:active, .formControl:focus, .custom-select:active, .custom-select:focus {
    outline: none;
    box-shadow: none;
    border-color: #000;
    background-color: transparent;
  }
  
  .col-form-label {
    color: #000;
    font-size: 13px;
  }
  
  .submitButton {
    height: 40px;
    border: none;
    border-radius: 8px !important;
    font-size: 11px;
    letter-spacing: .2rem;
    text-transform: uppercase;
    background: var(--amplify-colors-primary-80);
    color: #fff;
  }
  .submitButton:hover {
    color: #fff;
    background-color: var(--amplify-colors-primary-100);
    box-shadow: 0 5px 30px 0 rgba(57, 69, 239, 0.2);
  }
  .submitButton:active, .submitButton:focus {
    outline: none;
    box-shadow: none;
  }
  
  .contact-wrap {
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #efefef;
  }
  .formH3 {
    color: #000;
    color: var(--amplify-colors-primary-80);
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 30px;
  }
  
  .successMessage {
    color: var(--amplify-colors-primary-80);
    font-size: 18px;
    font-weight: bold;
  }

  .failureMessage {
    color: #B90B0B;
    font-size: 18px;
    font-weight: bold;
  }
/*   
  #message {
    resize: vertical;
  }
  
  #form-message-warning, #form-message-success {
    display: none;
  }
  
  #form-message-warning {
    color: #B90B0B;
  }
  
  #form-message-success {
    color: #55A44E;
    font-size: 18px;
    font-weight: bold;
  }
  
  .submitting {
    display: none;
    font-weight: bold;
    font-size: 12px;
    color: #000;
  }
   */