.email-input {
    /* Add your existing styles for email input here */
}

.submitButton {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #00057b ;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}