
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.remove-a-hover:hover {
    color: unset !important ;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.pulse {
  animation: pulse 2s infinite;
}

.hidden {
  display: none;
}


.root-layout-content {
}

.root-layout-container {
  /* background-color: var(--amplify-colors-neutral-20); */
  background-color: var(--amplify-colors-neutral-20);
  background-color: white;
}

.max-width {
  max-width: 1440px;
  margin: 0px auto;
}

.root-layout-outlet {
  background-color: var(--amplify-colors-neutral-10);
  background-color: white;
  /* border: 1px solid var(--amplify-colors-neutral-60); */
  min-height: 100vh;
}

.marketing-layout-outlet {
  background-color: var(--amplify-colors-neutral-10);
  background-color: white;
}




/* Bootstrap */


.navbar-override {
  padding-top: .20rem !important;
  padding-bottom: .20rem !important;
}



.divider {
  /* border-bottom: 1px solid #ccc; Creates a line at the bottom of the section */
  min-height: 100px;
  /* margin: 40px 0; Adds some vertical space before and after the divider */
  /* padding: 40px; Adds some space around the content inside the divider */
}



[data-amplify-authenticator] {
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(--amplify-colors-primary-100);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-primary-60);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-primary-100);
  --amplify-components-tabs-item-color: var(--amplify-colors-primary-80);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-primary-100);
  --amplify-components-button-link-color: var(--amplify-colors-primary-80);
  --amplify-components-button-link-hover-color: var(--amplify-colors-primary-100);
  --amplify-components-button-primary-hover-background-color: var(--amplify-colors-primary-80);
}


input:invalid {
  border-color: red;
}


textarea:invalid {
  border-color: red;
}


.noBootstrap {
  all: unset !important;
}






/* Animations */




@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);

  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 1s;
  visibility: visible !important;
  
  animation-fill-mode: both;
}

.zIndexHigh {
  z-index: 4;
}

.zIndexLow {
  z-index: 1;
}

.original-price {
  text-decoration: line-through;
  color: red; /* Optional: to indicate the original price is no longer valid */
  margin-right: 10px; /* Optional: to add some space between the prices */
}

.discounted-price {
  color: green; /* Optional: to highlight the new price */
  font-weight: bold; /* Optional: to make the new price stand out */
}