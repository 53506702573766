@import url('../../../assets/fonts/fonts.css');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');


.mount-theme .poppins {
    font-family: 'Poppins', sans-serif;
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);

    }
}

.mount-theme .message-component {
    color: #344b5b;
}

.mount-theme .checked {
    content: url('../MountTheme/assets/images/check-icon-white.png'); /* replace with your check icon url */
}

.mount-theme .downloadButton {
    color: white;
    background-color: #344b5b;
    font-family: 'Poppins', sans-serif;
}
.mount-theme .select-file-button {
    width: 325px;
    padding: 10px;
    animation: pulse 2s infinite;
    background-color: #344b5b
    ;
    color: white;
    border-radius: 35px;
    font-family: "poppins", sans-serif;
    max-width: 1000px;
    font-size: 16px;
}

.mount-theme .select-file-button:hover {
    transform: scale(1.1);
    animation: none;
}
.mount-theme .banner {
    text-align: center;
    color: #344b5b;
    /* border: 2px solid white; */
    font-size: 1.25em;
    font-family: "poppins", sans-serif;
}
.mount-theme {
    background-color: #fff;
    color: #000;
    font-family: "JerinBold", sans-serif;
    font-size: 16px;
}
.mount-theme .leftAlign {
    text-align: left;
  }
.mount-theme h1 {
    font-family: "JerinBold", sans-serif;
    color: #344b5b;
}
.mount-theme .container {
    max-width: 2600px;
    /* max-width: 1600px; */
}

.mount-theme .bd-highlight {
    margin: 0px;
    margin-bottom: 0px;
    padding: 2px !important;
}


.mount-theme .corner-button:before,
.mount-theme .corner-button:after,
.mount-theme .corner-button span:before,
.mount-theme .corner-button span:after {
    display: block;
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
}

.mount-theme .corner-button {
    display: inline-block;
    font-family: 'JerinBold';
    font-size: 20px;
    position: relative;
    min-width: 150px;
    min-height: 30px;
    margin: 0 20px;
    background: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    color: #F5F3F5;
    padding: 20px 20px;
    box-sizing: content-box;
    border: 2px solid transparent;
    text-align: center;
    line-height: 30px;
    transition: 0.5s color;
}

.mount-theme .corner-button:before {
    top: -2px;
    left: -2px;
    border-top: 2px solid #F5F3F5;
    border-left: 2px solid #F5F3F5;
    transition: 0.5s all;
}

.mount-theme .corner-button:after {
    top: -2px;
    right: -2px;
    border-top: 2px solid #F5F3F5;
    border-right: 2px solid #F5F3F5;
    transition: 0.5s all;
}

.mount-theme .corner-button span {
    display: block;
}

.mount-theme .corner-button span:before {
    bottom: -2px;
    left: -2px;
    border-bottom: 2px solid #F5F3F5;
    border-left: 2px solid #F5F3F5;
    transition: 0.5s all;
}

.mount-theme .corner-button span:after {
    bottom: -2px;
    right: -2px;
    border-bottom: 2px solid #F5F3F5;
    border-right: 2px solid #F5F3F5;
    transition: 0.5s all;
}

.mount-theme .corner-button:hover {
    color: #465159;
}

.mount-theme .corner-button:hover:before,
.mount-theme .corner-button:hover:after {
    border-color: #F5F3F5;
    width: 100%;
    height: 100%;
}

.mount-theme .corner-button:hover span:before,
.mount-theme .corner-button:hover span:after {
    border-color: #F5F3F5;
    width: 100%;
    height: 100%;
}

.mount-theme .nav-pills .nav-link:hover {
    background-color: rgb(255, 255, 255, 0.3);
    color: #F5F3F5;
}

.mount-theme .nav-pills .nav-link.active,
.mount-theme .nav-pills .show > .nav-link {
    background-color: rgb(255, 255, 255, 0.3);
}

.mount-theme .btn-primary-gallery {
    background-color: #fff;
    color: #000;
    /* border-color: #fff; */
    border-radius: 35px;
}

.mount-theme .nonactive-btn:hover,
.mount-theme .nonactive-btn:focus,
.mount-theme .nonactive-btn:focus-visible,
.mount-theme .nonactive-btn:active {
    background-color: white !important;
    color:#344b5b !important;
    border-color: #000 !important;
}

.mount-theme .btn-secondary {
    background-color: #000;
    color: #fff;
    border-color: #000;
    border-radius: 35px;
}

.mount-theme .btn-secondary:hover,
.mount-theme .btn-secondary:focus,
.mount-theme .btn-secondary:focus-visible,
/* .mount-theme .btn-secondary:active {
    background-color: #fff !important;
    color: #000 !important;
    border-color: #fff !important;
} */

.mount-theme .topbar {
    padding: 20px 20px;
}

.mount-theme .topbar-left p {
    font-family: 'JerinRegular';
    font-size: 24px;
    margin: 0;
}

.mount-theme .topbar-right {
    text-align: right;
}

.mount-theme .topbar-right p {
    font-size: 24px;
    margin: 0;
}
.mount-theme .banner-section {
    padding: 15px;
    margin-left: 10px;
    margin-right: 10px;
    /* font-size: 1.5em */
    
  }
.mount-theme .title-section {
    /* padding: 50px; */
    background-image: url('./assets/images/white-cloud-extended.png');
    background-attachment: fixed;
    /* background-image: url('./assets/images/dark-balck-cloud.png.png'); */
    
    /* background-repeat: no-repeat; */
    /* background-position: center; */
    /* background-size: cover; */
    height: 800px;
    /* height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.mount-theme .title-content {
    margin-top: -250px;
    /* margin-top: calc(-250px - 2vw);  */
    /* text-align: center; */
}

.mount-theme .title-content h1 {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 600; 
    /* font-size: 3.25em; */
    margin-bottom: 10px;
    /* font-size: calc(40.0px + (88.0 - 40.0) * ((100vw - var(--minViewportSize) * 1px) / (var(--maxViewportSize) - var(--minViewportSize)))); */
    font-size: calc(32px + 2vw); /* adjust as needed */

    /* center text */
    /* line-height: 87px; */
    /* no wrap */
    /* white-space: nowrap; */
    /* resize text to fit */
    
    
}

.mount-theme .title-content p {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: calc(16px + 1vw); /* adjust as needed */

    /* font-size: 2em; */
}

.mount-theme .title-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mount-theme .title-content ul li {
    margin: 0 10px;
}

.mount-theme .title-content ul li a img {
    width: 60px;
}

.mount-theme .banner-button {
    margin-top: 30px;
    font-family: 'poppins', sans-serif;
    font-weight: 300;
}

.mount-theme .banner-button .btn {
    width: 250px;
    margin: 0 15px;
    padding: 15px;
    
}

.mount-theme .main-heading {
    text-align: center;
    font-family: 'RoxboroughCF';
    font-size: 60px;
    /* border-bottom: solid 5px #000; */
    padding-bottom: 15px;
    margin-bottom: 0px;
}

.mount-theme .nav-pills .nav-item {
    width: 50%;
}

.mount-theme .nav-pills .nav-item a {
    width: 100%;
    color: #000;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    border-radius: 0;
    padding: 13px 5px;
}

.mount-theme .nav-pills .nav-item a:hover {
    background-color: #000;
    color: #fff;
}

.mount-theme .nav-pills .nav-item a.active {
    background-color: #000;
    color: #fff;
}
.mount-theme .footer-section {
    margin-top: 250px;
    height: 200px;
    /* border: 2px solid blue; */
}
.mount-theme .tab-section {
    /* padding: 50px; */
    padding-top: 10px;
    /* border: #000 2px solid; */
    box-shadow: 0px 0px 10px 0px #344b5b ;
    margin: -250px auto;
    max-width: 90%;
    background-color: white;
}

.mount-theme .gallery-tabs {
    min-height: 300px;
}

.mount-theme .tab-btn-main .nav-pills {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px;
    margin-bottom: 30px;
}

.mount-theme .tab-content-main {
    margin: 20px auto 0;
    /* border: 5px solid #000; */
}

.mount-theme .light-gallery-main .lg-react-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.mount-theme .light-gallery-main a {
    flex: 0 0 33%;
    position: relative;
}

.mount-theme .light-gallery-main img {
    width: 100%;
    height: 500px;
    margin-bottom: 7px;
    object-fit: cover;
    object-position: center;
}
.mount-theme .find-my-photos-title {
    margin-bottom: 15px;
    padding-top: 15px;
    font-size: 1.5rem;
}
.mount-theme .upload-photo-tab {
    margin: 0 auto 25px;
    max-width: 1200px;
}

.mount-theme .upload-box-main {
    /* background-image: url('./assets/images/image-preview-bg.webp'); */
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 100px 0; */
}

.mount-theme .upload-photo-box {
    background-color: #fff;
    padding: 4px;
    max-width: 310px;
    margin: 0 auto;
    
}

.mount-theme .upload-photo-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.mount-theme .upload-box {
    /* max-width: 500px; */
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;

}

.mount-theme .upload-box h1 {
    font-weight: 400;
    font-family: "JerinBold", sans-serif;
    
    /* font-size: 80px; */
    /* max-width: 75%; */
    /* margin: auto auto 30px; */
}
.mount-theme .upload-box p {
    /* font-family: "JerinBold", sans-serif; */
    color: #344b5b;
    max-width: 50%;
    font-family: 'Poppins', sans-serif;
    border: none;
    font-size: 16px;
}

.mount-theme .upload-file-btn {
    text-align: center;
    margin: 0px
}
.mount-theme .no-preview .select-file-button{
    border-radius: 2;
    /* width: 90%; */
    /* max-width: 10000px; */
    margin: 10px;
}

.mount-theme .upload-file-btn input {
    max-width: 10px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
}

.mount-theme .upload-file-btn label {
    /* max-width: 200px; */
    /* width: 100%; */
    margin: 0 auto;
    cursor: pointer;
}

.mount-theme .upload-file-btn label img {
    width: 100%;
}

.mount-theme .image-select {
    width: 30px;
    height: 30px;
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0;
}

.mount-theme .image-select img {
    width: 30px;
    height: 30px;
}

.mount-theme .light-gallery-main a:hover .image-select {
    opacity: 0.5;
}

.mount-theme .light-gallery-main a:hover .image-select.selected {
    opacity: 1;
}

.mount-theme .light-gallery-main .image-select.selected {
    opacity: 1;
}




.mount-theme .active-btn {
    background-color: white;
    color: #344b5b;
}

.mount-theme .nonactive-btn {
    background-color: #344b5b;
    color: white;
}


.mount-theme #downloadFullGalleryButton {
    margin: 20px;
    padding: 9px;
    border-radius: 10px;
    background-color: #344b5b;
    color: #fff;
    font-size: 16px;
    /* font-weight: 600; */
    border: none;
    cursor: pointer;
    border: transparent 2px solid;

}
.mount-theme #selectAllButton {
    margin: 20px;
    padding: 9px;
    border-radius: 10px;
    background-color: #344b5b;
    color: #fff;
    font-size: 16px;
    /* font-weight: 600; */
    border: none;
    cursor: pointer;
    border: transparent 2px solid;
}
.mount-theme #clearAllButton {
    margin: 20px;
    padding: 9px;
    border-radius: 10px;
    background-color: #344b5b;
    color: #fff;
    font-size: 16px;
    /* font-weight: 600; */
    border: none;
    cursor: pointer;
    border: transparent 2px solid;
}

.mount-theme #selectAllButton:hover,
.mount-theme #clearAllButton:hover,
.mount-theme #downloadFullGalleryButton:hover {
    background-color: #fff;
    color: #000;
    border: #000 2px solid;
}
.mount-theme #downloadFullGalleryButton:active {
    background-color: #000;
    color: #fff;
    border: #000 2px solid;
}
.mount-theme #downloadFullGalleryButton:focus {
    outline: none;
}
.mount-theme #downloadFullGalleryButton:disabled {
    background-color: #000;
    color: #fff;
    border: #000 2px solid;
    opacity: 0.5;
    cursor: not-allowed;
}
.mount-theme #downloadFullGalleryButton:disabled:hover {
    background-color: #000;
    color: #fff;
    border: #000 2px solid;
}
.mount-theme #downloadFullGalleryButton:disabled:active {
    background-color: #000;
    color: #fff;
    border: #000 2px solid;
}




@media (max-width: 1199px) {
    .mount-theme .title-content h1 {
        /* font-size: 2.25em; */
        /* font-size: 80px; */
    }

    .mount-theme .title-content p {
        /* font-size: 1.5em; */
    }   
    .mount-theme .light-gallery-main a {
        flex: 0 0 49.4%;
    }
    .mount-theme .banner-button .btn{
        width: 200px;
    }
    .mount-theme .btn-primary-gallery  {
    }
    .mount-theme .tab-section {
        margin: -350px auto;
    }

}

@media (max-width: 991px) {
    .mount-theme .title-content h1 {
        /* font-size: 1.75em; */
    }
    .mount-theme .title-content p {
        /* font-size: 1em; */
    }   
    .mount-theme .gallery-image img {
        height: 250px;
    }
    .mount-theme .title-content {
    }
    .mount-theme .tab-section {
        /* padding: 50px; */
        margin: -360px auto;
    }
    .mount-theme .upload-box p {
        max-width: 70%;
    }
}

@media (max-width: 767px) {
    .mount-theme .topbar-left p {
        font-size: 15px;
    }

    .mount-theme .topbar-right p {
        font-size: 15px;
    }

    .mount-theme .title-section {
        padding: 20px 15px;
    }

    .mount-theme .title-content ul li {
        margin: 0 5px;
    }

    .mount-theme .title-content ul li a img {
        width: 35px;
    }
    .banner-section .banner:first-child {
        display: none;
      }
      .banner-section {
        justify-content: center !important;
      }

    .mount-theme .tab-content-main {
        margin: 20px auto 0;
        padding: 0px;
    }

    .mount-theme .corner-button {
        font-size: 16px;
        min-width: 115px;
        margin: 0 10px;
        padding: 14px 20px;
    }
    .mount-theme .upload-file-btn label {
        font-size: 16px;

    }
    .mount-theme .light-gallery-main a {
        flex: 0 0 100%;
    }

    .mount-theme .main-heading {
        font-size: 40px;
    }

    .mount-theme .upload-box h1 {
        font-size: 50px;
    }
    
    .mount-theme .banner-button .btn{
        font-size: 16px;
        width: 185px;
    }
    .mount-theme .no-preview .select-file-button {
        font-size: 16px;
    }
    .mount-theme .btn-primary-gallery  {
    }
 
}

@media (max-width: 450px) {
    .mount-theme .title-content h1 {
        /* font-size: 1em; */
    } 
    .mount-theme .select-file-button {
        width: 250px;
    }
    .mount-theme .corner-button {
        font-size: 14px;
        min-width: 100px;
        margin: 0 10px;
        padding: 7px 12px;
    }

    .mount-theme .corner-button::before,
    .mount-theme .corner-button::after,
    .mount-theme .corner-button span::before,
    .mount-theme .corner-button span::after {
        width: 15px;
        height: 15px;
    }

    .mount-theme .gallery-image img {
        height: 180px;
    }

    .mount-theme .upload-photo-box img {
        /* height: 350px; */
    }

    .mount-theme .upload-box p {
        font-size: 16px;
        max-width: 90%;
    }

    /* .mount-theme .banner-button .btn {
        width: 140px;
        margin: 0 2px;
        font-size: 14px;
    } */

    .mount-theme .upload-photo-box {
        max-width: 260px;
    }

    .mount-theme .upload-box-main {
        /* padding: 50px 0; */
    }

        
    .mount-theme .banner-button .btn{
        font-size: 14px;
        width: 185px;
        margin: 0 2px;
        
        
    }
    .mount-theme .no-preview .select-file-button {
        font-size: 14px;
    }
    .mount-theme .btn-primary-gallery  {
    }

}


@media (max-width: 350px) {
    * {
    }
}
