.uploadedImages {
  max-width: 100px;
}

.mainTitle {
  font-size: 48px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.5px;
}

.galleryContainer {
  margin: 20px;
  padding: 15px;

  /* box-shadow: 4px 8px 8px hsl(0deg 0% 0% / 0.38); */
  /* box-shadow: 2px 0px 11px hsl(0deg 0% 0% / 0.38); */
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* background-color: rgba(216, 216, 216, 0.733); */
}

.galleryContainer:hover {
  /* box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22); */
}

.ImageContainer {
  position: relative;
}
.lightGalleryMain {
  /* border: 2px solid #fff; */
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
}

.lightGalleryMain {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
/* .lightGalleryMain:hover {
    transform: translateY(-5px);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  } */

.uploadedImagesHeader {
  font-family: "Lato", sans-serif;
  /* border: 10px    solid #f8f9fa; */
  font-size: 20px;
  text-align: left;
  font-weight: 600;
}

.uploadedImagesUploader {
  font-family: "Lato", sans-serif;
  /* border: 10px    solid #f8f9fa; */
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 10px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #1475cf;
  height: 300px;
  width: 500px;
  cursor: pointer;
  border-radius: 5px;
}

.cursorNone {
  cursor: none;
  border: 10px solid red;
}

.dropZone {
  margin: 50px;
  /* min-width: 800px; */
  margin-bottom: 0px;
  margin-top: 0px;
}
.clicked {
  border: 4px solid var(--amplify-colors-primary-80);
}

.storageManagerContainer {
  padding: 40px;
}
.cardGallery {
    background-color: rgb(255, 255, 255);
    border-color: rgb(137, 148, 159);
    border-radius: 4px;
    border-style: dashed;
    border-width: 1px;
}

/* .storageManagerContiner {
    max-height: 100px;
} */

.headerImageCount {
    /* margin-bottom: -20px; */
    font-size: 14px;
}

.pagination{
    margin-top: 20px;
    margin-bottom: 20px;
}
.buttonContainer > button {
    font-size: 14px;
}