.overviewTableHeader {
  min-width: 460px;
}

.tableText {
  /* border: 2px solid red; */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin:10px;
}

h2 {
  /* text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  /* margin-bottom: 20px; */
  /* text-align: left;
  font-family: "Lato", sans-serif;
  letter-spacing: 0.5px; */ 
}

.text {
  text-align: left;
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: gray;
}

.subtext {
  text-align: left;
  padding-left: 10px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
}

@media (max-width: 768px) {
  /* CSS rules for medium-sized screens go here */

  .overviewTableHeader {
    min-width: 100%;
    border: 2px solid red;
  }
}
