

.accountTable {
    max-width: 1440px;
    padding: 40px;
}


.user-profile {
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
    text-align: center;
        color: var(--amplify-colors-primary-80)

}
.user-avatar {
    margin: 0 0 1rem 0;
}
.avatar-img {
    width: 90px;
    height: 90px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}
.user-name {
    margin: 0 0 0.5rem 0;
}
.user-email {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 400;
    color: #9fa8b9;
}
.about {
    margin: 2rem 0 0 0;
    text-align: center;

}
.about-h5 {
    margin: 0 0 15px 0;
    color: #007ae1;
    color: var(--amplify-colors-primary-80)
}
.about-p {
    font-size: 0.825rem;
}
.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #ffffff;
    color: #2e323c;
    
}

.card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}

.buttonContainer {
    margin: 30px;
}
.contact-wrap {
    box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #efefef;
  }


  
  .button {
    background: var(--amplify-colors-primary-80);
  }  .button:hover {
    color: #fff;
    background-color: var(--amplify-colors-primary-100);
  }
  .button:active, .button:focus {
    outline: none;
    box-shadow: none;
  }