.textContainer {
  padding-right: 30px;
  padding-left: 20px;
  /* max-width: 1000px; */
  min-width: 100%;
}
.pageTitle {
  font-size: 18px;
  text-align: left;
  margin: 0px;
}
.sideBarHeader {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 30px;
  transition: opacity 0.3s;
  opacity: 1;
  color: white;
  color: #7d84ab;
  color: var(--amplify-colors-white);
}

.menuHeader,
.menuHeaderThemeText {
  padding-left: 10px !important;
  font-size: 0.9em;
  color: white;
}
.menuHeaderThemeText {
  box-sizing: border-box;
  color: rgb(125, 132, 171);
  color: var(--amplify-colors-neutral-40);
  display: inline;
  /* font-family: "Poppins", sans-serif; */
  font-size: 24px;
  font-weight: 400;
  height: auto;
  letter-spacing: 2px;
  line-height: 17.28px;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  overflow-wrap: break-word;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 30px;
  text-align: left;
  text-size-adjust: 100%;
  width: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.menuTitle {
  padding-left: 20px !important;
}

.colorIcon {
  width: 25px;
  height: 25px;
  margin-right: 20px;
  /* max-width: 10px; */
  /* max-height: 10px; */
}

.ImageIcon {
  max-width: 150px;
  /* min-height: 100px; */
  padding-right: 20px;
  /* max-width: 10px; */
  /* max-height: 10px; */
}

.imgContainer {
  text-align: left;
}

.themeIcon {
  /* margin-right: 20px; */
  /* width: 30px; */
  font-size: 10px !important;
}

.theme-dropdown {
  /* border:2px solid #880404; */
  background-color: var(--amplify-colors-primary-80);
  font-size: 16px;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  border-color: #ffffff29;
  color: white;
  min-width: 120px;
}

.theme-dropdown:hover {
  background-color: var(--amplify-colors-primary-60);
  border: none;
  color: white;
  border: 1px solid black;
}
.theme-dropdown:focus {
  border: none;
  box-shadow: none;
}
.dropdown-item {
  font-size: 14px;
}

.dropdown-menu {
  min-width: none;
}

.projectButtons {
  border: 2px solid white;
  color: white;
  font-size: 12px;
}
.projectButtons:hover {
  border: 2px solid white;
  color: white;
  background-color: #4a179129;
}





.imageUploaderButton {
  /* max-width: 100px; */
  min-width: 50px;
  margin-left: 10px;
  margin-right: 10px;
}
.imageUploaderOpened {
  border: 2px solid red;
  margin-top: 40px;
}




.alertBox {
  max-width: 70%;
  /* margin-left: 10px; */
}

.buttonContainer {
  margin-top: 20px;
  margin-bottom: 20px;
}

.backgroundImage {
  /* min-height: 100px; */
  /* max-width: 150%; */
}

/* Extra small devices (portrait phones, less than 576px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
    .textContainer {
        min-width: 100%;
      }
      .ImageIcon {
        max-width: 70%;
      }
}  

/* Medium devices (tablets, 768px and up) */
@media (min-width: 576px) and (max-width: 768px) {
    .textContainer {
        min-width: 70%;
    }
    .ImageIcon {
        max-width: 70%;
      }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XXL devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
